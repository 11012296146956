.observacoes-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
}

.observacoes-sidebar h2 {
  margin-bottom: 10px;
}

.observacoes-sidebar textarea {
  width: 100%;
  margin-bottom: 10px;
}

.sidebar-buttons {
  display: flex;
  justify-content: space-between;
}

.sidebar-buttons button {
  padding: 5px 10px;
  cursor: pointer;
}

.debug-info {
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.debug-info p {
  margin: 5px 0;
}

.debug-info strong {
  color: #666;
}
