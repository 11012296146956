.App {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.content {
  flex: 1;
  transition: margin-left 0.3s ease;
  width: 100%;
  overflow-x: hidden;
}

.sidebar-open .content {
  margin-left: 250px;
  width: calc(100% - 250px);
}

.sidebar-closed .content,
.sidebar-collapsed .content {
  margin-left: 50px;
  width: calc(100% - 50px);
}
