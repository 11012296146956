.checklist-verificacao {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.checklist-verificacao h1 {
  font-size: 1.8em;
  margin-bottom: 20px;
  word-wrap: break-word;
  padding-right: 20px;
}

.projeto-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.checklist-detalhes {
  height: calc(100vh - 140px);
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}

.adicionar-item {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.adicionar-item input,
.adicionar-verificacao input,
.adicionar-subetapa input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.adicionar-item button,
.adicionar-verificacao button,
.adicionar-subetapa button,
.checklist-table button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.checklist-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.checklist-table th,
.checklist-table td {
  padding: 8px;
  border: 1px solid #ddd;
  vertical-align: middle;
}

.checklist-table th {
  background-color: #4CAF50;
  color: white;
}

.fase-row {
  background-color: #e6f3ff;
}

.subetapa-row {
  background-color: #f0f0f0;
}

.verificacao-row {
  background-color: #ffffff;
}

.checklist-table button {
  margin-right: 5px;
  padding: 5px 10px;
}

.checklist-table button.excluir {
  background-color: #dc3545;
}

.toggle-collapse {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #007bff;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.toggle-collapse.collapsed {
  transform: rotate(-90deg);
}

select, input[type="number"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.modal-content button {
  margin-right: 10px;
}

.acoes-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 50px;
}

.acoes-buttons button {
  padding: 3px 6px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 3px;
  width: 100%;
}

.checklist-table select {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.checklist-table td {
  vertical-align: middle;
}

/* Ajuste das larguras e alinhamento das colunas */
.checklist-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.checklist-table th,
.checklist-table td {
  padding: 8px;
  border: 1px solid #ddd;
  vertical-align: middle;
}

/* Coluna ID */
.checklist-table th:nth-child(1),
.checklist-table td:nth-child(1) {
  width: 5%;
  text-align: center;
}

/* Coluna Tipo */
.checklist-table th:nth-child(2),
.checklist-table td:nth-child(2) {
  width: 6%;
  text-align: left;
}

/* Coluna Disc */
.checklist-table th:nth-child(3),
.checklist-table td:nth-child(3) {
  width: 4%;
  text-align: center;
}

/* Coluna Fase/Tarefa */
.checklist-table th:nth-child(4),
.checklist-table td:nth-child(4) {
  width: 32%;
  text-align: left;
}

/* Coluna Ação */
.checklist-table th:nth-child(5),
.checklist-table td:nth-child(5) {
  width: 10%;
  text-align: left;
}

/* Coluna Responsável */
.checklist-table th:nth-child(6),
.checklist-table td:nth-child(6) {
  width: 8%;
  text-align: left;
}

/* Coluna Data */
.checklist-table th:nth-child(7),
.checklist-table td:nth-child(7) {
  width: 8%;
  text-align: center;
}

/* Coluna Status */
.checklist-table th:nth-child(8),
.checklist-table td:nth-child(8) {
  width: 8%;
  text-align: left;
}

/* Coluna Porcentagem */
.checklist-table th:nth-child(9),
.checklist-table td:nth-child(9) {
  width: 6%;
  text-align: center;
}

/* Coluna Ações */
.checklist-table th:nth-child(10),
.checklist-table td:nth-child(10) {
  width: 80px !important;
  min-width: 250px;
  text-align: center;
  padding: 4px;
}

.acoes-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  width: 35px;
  margin: 0 auto;
}

.acoes-buttons button {
  padding: 2px 4px;
  font-size: 10px;
  white-space: nowrap;
  border-radius: 3px;
  width: 35px;
  min-width: unset;
}

/* Cores específicas para cada tipo de botão */
.acoes-buttons button:nth-child(1) { /* Editar */
  background-color: #007bff;
  color: white;
}

.acoes-buttons button:nth-child(2) { /* Excluir */
  background-color: #dc3545;
  color: white;
}

.acoes-buttons button:nth-child(3) { /* Obs */
  background-color: #17a2b8;
  color: white;
}

/* Ajuste para os selects nas colunas */
.checklist-table select {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Ajuste para inputs */
.checklist-table input[type="text"],
.checklist-table input[type="number"],
.checklist-table input[type="date"] {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Ajuste específico para input de porcentagem */
.checklist-table input[type="number"] {
  width: 50px;
  text-align: right;
  padding-right: 15px;
}

/* Ajuste para os botões de ação */
.acoes-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 50px;
}

.acoes-buttons button {
  padding: 3px 6px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 3px;
  width: 100%;
}

.verificacao-nome {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
}

.habilitar-subverificacoes {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.2s ease;
}

.habilitar-subverificacoes:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.subverificacao-inline-form {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
  flex-wrap: nowrap;
  position: absolute;
  background: white;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  width: fit-content;
  min-width: 800px;
}

.subverificacao-inline-form input {
  width: 700px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.subverificacao-buttons {
  display: flex;
  gap: 6px;
  white-space: nowrap;
}

.adicionar-subverificacao-btn,
.cancelar-subverificacao-btn {
  padding: 4px 8px;
  font-size: 0.9em;
  border-radius: 4px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.adicionar-subverificacao-btn {
  background-color: #28a745;
  color: white;
}

.cancelar-subverificacao-btn {
  background-color: #dc3545;
  color: white;
}

.subverificacao-row {
  background-color: #f8f9fa;
}

.subverificacao-row td:first-child {
  padding-left: 60px;
}

/* Ajustes para melhorar o alinhamento e espaçamento */
.verificacao-row td,
.subverificacao-row td {
  vertical-align: middle;
}

.verificacao-row select,
.subverificacao-row select,
.verificacao-row input,
.subverificacao-row input {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

/* Ajuste para o botão de colapso */
.toggle-collapse {
  margin-right: 8px;
}

/* Ajuste para as ações */
.acoes-buttons {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
}

.acoes-buttons button {
  padding: 4px 8px;
  font-size: 12px;
}

/* Estilo para indicar que uma verificação tem subverificações */
.verificacao-row.has-subverificacoes {
  background-color: #e6f3ff;
}

/* Ajustes para subverificações */
.subverificacao-row {
  background-color: #f8f9fa;
}

.subverificacao-row td {
  padding: 8px 4px;
  font-size: 0.95em;
}

.subverificacao-row td:first-child {
  padding-left: 40px; /* Indentação para mostrar hierarquia */
}

/* Estilo para verificações que têm subverificaçes */
.verificacao-row.has-subverificacoes {
  background-color: #e6f3ff;
}

.verificacao-row.has-subverificacoes td {
  border-bottom: 2px solid #b8daff;
}

/* Ajustes para campos de input e select nas subverificações */
.subverificacao-row input,
.subverificacao-row select {
  width: 100%;
  padding: 4px 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.subverificacao-row input[type="number"] {
  width: 60px;
}

.subverificacao-row input[type="date"] {
  width: 130px;
}

/* Botões de ação nas subverificações */
.subverificacao-row .acoes-buttons {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
}

.subverificacao-row .acoes-buttons button {
  padding: 3px 6px;
  font-size: 0.85em;
  white-space: nowrap;
}

/* Formulário inline para adicionar subverificações */
.subverificacao-inline-form {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
  flex-wrap: nowrap;
  position: absolute;
  background: white;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  width: fit-content;
  min-width: 800px;
}

.subverificacao-inline-form input {
  width: 700px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.subverificacao-buttons {
  display: flex;
  gap: 6px;
  white-space: nowrap;
}

.adicionar-subverificacao-btn,
.cancelar-subverificacao-btn {
  padding: 4px 8px;
  font-size: 0.9em;
  border-radius: 4px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.adicionar-subverificacao-btn {
  background-color: #28a745;
  color: white;
}

.cancelar-subverificacao-btn {
  background-color: #dc3545;
  color: white;
}

/* Botão de adicionar subverificação (+) */
.habilitar-subverificacoes {
  width: 20px;
  height: 20px;
  min-width: 20px;
  padding: 0;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.2s ease;
  margin-left: 8px;
}

.habilitar-subverificacoes:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

/* Campos desabilitados em verificações com subverificações */
.verificacao-row.has-subverificacoes input:disabled,
.verificacao-row.has-subverificacoes select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Ajuste do layout da tabela */
.checklist-table td {
  vertical-align: middle;
}

/* Ajuste para o nome da verificação */
.verificacao-nome {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
  position: relative;
}

/* Responsividade para telas menores */
@media (max-width: 1200px) {
  .subverificacao-inline-form {
    flex-direction: column;
    align-items: flex-start;
  }

  .subverificacao-buttons {
    margin-top: 4px;
  }
}

/* Alinhamento específico para colunas */
.col-porcentagem {
  text-align: right;
  width: 100px;
}

.col-acoes {
  text-align: center;
  width: 150px;
  white-space: nowrap;
}

/* Ajuste para fase e subetapa */
.col-fase,
.col-subetapa {
  font-weight: bold;
}

/* Ajuste para a coluna de ações */
.col-acoes {
  width: 200px !important;
  text-align: center;
}

.acoes-buttons {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.acoes-buttons button {
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  flex: 1;
}

/* Ajuste para o campo de porcentagem */
.checklist-table input[type="number"] {
  width: 60px;
  text-align: right;
  padding-right: 15px; /* Espaço para o símbolo % */
}

/* Estilo específico para os botões de ação */
.btn-observacoes {
  background-color: #17a2b8;
  color: white;
}

.btn-observacoes:hover {
  background-color: #138496;
}

/* Ajustes específicos para a coluna de ações e seus botões */
.col-acoes {
  width: 250px !important;
  padding: 4px !important;
}

.acoes-buttons {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.acoes-buttons button {
  padding: 4px 8px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 4px;
  flex: 1;
  min-width: 60px;
}

/* Cores específicas para cada tipo de botão */
.acoes-buttons button:nth-child(1) { /* Editar */
  background-color: #007bff;
  color: white;
}

.acoes-buttons button:nth-child(2) { /* Excluir */
  background-color: #dc3545;
  color: white;
}

.acoes-buttons button:nth-child(3) { /* Observações */
  background-color: #17a2b8;
  color: white;
}

/* Hover states */
.acoes-buttons button:hover {
  opacity: 0.9;
}

/* Ajuste para o cabeçalho da tabela */
.checklist-table thead th {
  position: sticky;
  top: 0;
  background-color: #4CAF50;
  z-index: 10;
  padding: 12px;
  color: white;
  border: 1px solid #ddd;
}

/* Ajuste para garantir que as bordas do cabeçalho fiquem visíveis */
.checklist-table thead th::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #ddd;
}

/* Ajuste para melhorar a visualização durante a rolagem */
.checklist-table {
  border-collapse: separate;
  border-spacing: 0;
}

/* Estilos específicos para os botões do Checklist de Verificação */
.checklist-table .acoes-buttons {
  display: flex;
  flex-direction: column; /* Mantém os botões em coluna */
  gap: 2px;
  justify-content: center;
  width: 60px;
}

.checklist-table .acoes-buttons button {
  padding: 3px 6px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 3px;
  width: 100%;
  min-width: 60px;
}

/* Cores específicas para os botões do Checklist */
.checklist-table .acoes-buttons button:nth-child(1) { /* Editar */
  background-color: #007bff;
  color: white;
}

.checklist-table .acoes-buttons button:nth-child(2) { /* Excluir */
  background-color: #dc3545;
  color: white;
}

.checklist-table .acoes-buttons button:nth-child(3) { /* Observações */
  background-color: #17a2b8;
  color: white;
}

/* Adicione junto aos outros estilos da tabela */
.has-observacoes {
  background-color: #90EE90 !important; /* Verde claro */
}

/* Garanta que a célula da coluna Tipo tenha a cor de fundo aplicada */
.checklist-table td.has-observacoes {
  background-color: #90EE90 !important;
}

/* Ajuste específico para verificações e subverificações */
.verificacao-row td.has-observacoes,
.subverificacao-row td.has-observacoes {
  background-color: #90EE90 !important;
}

.adicionar-item select {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
