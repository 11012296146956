.controle-entregaveis {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}

.controle-entregaveis h1 {
  font-size: 1.8em;
  margin-bottom: 20px;
  word-wrap: break-word;
  padding-right: 20px;
  position: relative;
  transition: all 0.3s ease;
}

/* Ajuste quando a sidebar estiver aberta */
.sidebar:not(.collapsed) + .main-content .controle-entregaveis h1 {
  margin-left: 0;
}

/* Ajuste quando a sidebar estiver fechada */
.sidebar.collapsed + .main-content .controle-entregaveis h1 {
  margin-left: 0;
}

.projeto-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.projeto-detalhes {
  height: calc(100vh - 140px);
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  max-width: 100%;
}

.projeto-detalhes h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  word-wrap: break-word;
  max-width: calc(100% - 40px);
}

.projeto-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  max-width: 100%;
}

.projeto-info p {
  margin: 0;
  flex: 1 1 auto;
  min-width: 200px;
  max-width: 100%;
}

.adicionar-item {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.adicionar-item input,
.adicionar-atividade input,
.adicionar-subetapa input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.adicionar-item button,
.adicionar-atividade button,
.adicionar-subetapa button,
.entregaveis-table button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.entregaveis-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.entregaveis-table th,
.entregaveis-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.entregaveis-table th {
  background-color: #4CAF50;
  color: white;
}

.fase-row {
  background-color: #e6f3ff;
}

.subetapa-row {
  background-color: #f0f0f0;
}

.atividade-row {
  background-color: #ffffff;
}

.entregaveis-table button {
  margin-right: 5px;
  padding: 5px 10px;
}

.entregaveis-table button.excluir {
  background-color: #dc3545;
}

.toggle-collapse {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #007bff;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.toggle-collapse.collapsed {
  transform: rotate(-90deg);
}

.fase-row td:nth-child(3),
.subetapa-row td:nth-child(3) {
  font-weight: bold;
  display: flex;
  align-items: center;
}

select, input[type="number"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.adicionar-atividade,
.adicionar-subetapa {
  display: flex;
  padding: 10px 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.modal-content button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.modal-content button:last-child {
  background-color: #dc3545;
}

.subatividade-row {
  background-color: #f8f9fa;
}

.subatividade-row td:first-child {
  padding-left: 60px;
}

.adicionar-subatividade {
  display: flex;
  gap: 10px;
  padding: 10px 0 10px 60px;
  background-color: #f8f9fa;
  align-items: center;
}

.adicionar-subatividade input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.subatividade-buttons {
  display: flex;
  gap: 8px;
}

.adicionar-subatividade-btn,
.cancelar-subatividade-btn {
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
}

.adicionar-subatividade-btn {
  background-color: #28a745;
}

.adicionar-subatividade-btn:hover {
  background-color: #218838;
}

.cancelar-subatividade-btn {
  background-color: #dc3545;
}

.cancelar-subatividade-btn:hover {
  background-color: #c82333;
}

.habilitar-subatividades {
  width: 24px;
  height: 24px;
  min-width: 24px;
  padding: 0;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.habilitar-subatividades:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

/* Estilo para o tooltip nativo */
[title] {
  position: relative;
  cursor: help;
}

.atividade-nome {
  position: relative;
  display: flex;
  align-items: center;
}

.subatividade-inline-form {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 500px;
}

.subatividade-inline-form input {
  flex: 1;
  min-width: 300px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.subatividade-buttons {
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.adicionar-subatividade-btn,
.cancelar-subatividade-btn {
  padding: 4px 8px;
  font-size: 0.9em;
  border-radius: 4px;
  white-space: nowrap;
  border: none;
  cursor: pointer;
}

.adicionar-subatividade-btn {
  background-color: #28a745;
  color: white;
}

.cancelar-subatividade-btn {
  background-color: #dc3545;
  color: white;
}

/* Ajusta as larguras das colunas */
.entregaveis-table th:nth-child(1), /* ID */
.entregaveis-table td:nth-child(1) {
  width: 8%;
}

.entregaveis-table th:nth-child(2), /* TIPO */
.entregaveis-table td:nth-child(2) {
  width: 10%;
}

.entregaveis-table th:nth-child(3), /* FASE / TAREFA */
.entregaveis-table td:nth-child(3) {
  width: 45%;
}

.entregaveis-table th:nth-child(4), /* STATUS */
.entregaveis-table td:nth-child(4) {
  width: 12%;
}

.entregaveis-table th:nth-child(5), /* PORCENTAGEM */
.entregaveis-table td:nth-child(5) {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  padding: 4px;
}

.entregaveis-table th:nth-child(6), /* AÇÕES */
.entregaveis-table td:nth-child(6) {
  width: 10%;
}

/* Ajuste no cabeçalho da tabela para ficar fixo */
.entregaveis-table thead th {
  position: sticky;
  top: 0;
  background-color: #4CAF50;
  z-index: 1;
}

/* Ajuste para o cabeçalho do projeto */
.projeto-detalhes h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  word-wrap: break-word; /* Permite quebra de palavras longas */
  padding-right: 20px;
}

/* Ajuste para as informações do projeto */
.projeto-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.projeto-info p {
  margin: 0;
  flex: 1;
  min-width: 200px;
}

/* Ajuste para o campo de adicionar nova fase */
.adicionar-item {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.adicionar-item input {
  flex: 1;
  min-width: 200px;
  max-width: calc(100% - 150px); /* Garante espaço para o botão */
}

.adicionar-item button {
  white-space: nowrap;
  min-width: 140px;
}

/* Ajuste para quando a tela for muito pequena */
@media (max-width: 600px) {
  .adicionar-item {
    flex-direction: column;
  }

  .adicionar-item input {
    max-width: 100%;
  }

  .adicionar-item button {
    width: 100%;
  }

  .projeto-info p {
    min-width: 100%;
  }
}

/* Estilo para atividades que têm subatividades */
.atividade-row.has-subatividades {
  background-color: #e6f3ff;
}

.atividade-row.has-subatividades td {
  border-bottom: 2px solid #b8daff;
}

/* Campos desabilitados em atividades com subatividades */
.atividade-row.has-subatividades select:disabled,
.atividade-row.has-subatividades input:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Ajuste para subatividades */
.subatividade-row {
  background-color: #f8f9fa;
}

.subatividade-row td:first-child {
  padding-left: 40px; /* Indentação para mostrar hierarquia */
}

.subatividade-row td {
  padding: 8px 4px;
  font-size: 0.95em;
}

/* Ajuste para os botões de ação */
.acoes-buttons {
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.acoes-buttons button {
  padding: 4px 8px;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 4px;
  min-width: 60px;
  flex: 0 1 auto;
}

/* Ajuste para a célula de ações */
.entregaveis-table td:last-child {
  text-align: center;
  padding: 8px 4px;
}

/* Cores específicas para cada tipo de botão */
.acoes-buttons button:nth-child(1) { /* Editar */
  background-color: #007bff;
  color: white;
}

.acoes-buttons button:nth-child(2) { /* Excluir */
  background-color: #dc3545;
  color: white;
}

.acoes-buttons button:nth-child(3) { /* Observações */
  background-color: #17a2b8;
  color: white;
}

/* Hover states */
.acoes-buttons button:hover {
  opacity: 0.9;
}

/* Ajuste para o input de porcentagem */
input[type="number"] {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  text-align: right;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
