.cadastro-projetos {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.novo-projeto-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.novo-projeto-btn:hover {
  background-color: #218838;
}

.erro {
  color: red;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffe6e6;
  border-radius: 4px;
}

.projeto-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button.excluir {
  background-color: #dc3545;
}

button.excluir:hover {
  background-color: #c82333;
}

button.cancelar {
  background-color: #6c757d;
}

button.cancelar:hover {
  background-color: #5a6268;
}

button.copiar {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 16px;
  margin-left: 5px;
}

button.copiar:hover {
  background-color: #218838;
}

.projetos-lista {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
}

tr:hover {
  background-color: #f5f5f5;
}

.disciplinas-section {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.section-toggle {
  cursor: pointer;
  user-select: none;
  margin-bottom: 15px;
}

.disciplinas-form {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.disciplinas-table {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse;
}

.disciplinas-table th,
.disciplinas-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.disciplinas-table th {
  background-color: #f8f9fa;
}

.projetos-lista td:last-child {
  white-space: nowrap;
  min-width: 200px;
}

.projetos-lista button {
  margin-right: 5px;
}