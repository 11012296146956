.sidebar {
  width: 220px;
  height: 100vh;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 50px;
  padding: 20px 0;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sidebar-header h2 {
  margin: 0;
  color: #333;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  color: #333; /* Cor do botão */
  opacity: 1; /* Sempre visível */
  transition: color 0.3s ease;
}

.toggle-button:hover {
  color: #007bff; /* Cor ao passar o mouse */
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  padding: 0;
}

.sidebar nav ul li a {
  display: block;
  padding: 12px 20px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.sidebar nav ul li a:hover {
  background-color: #f0f0f0;
}

.sidebar.collapsed nav ul li a {
  padding: 12px;
  text-align: center;
}

.sidebar.collapsed nav ul li a span {
  display: none;
}

.sidebar-content {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar-content {
  opacity: 0;
}

.sidebar.collapsed .sidebar-header h2 {
  display: none;
}

.sidebar.collapsed nav {
  display: none;
}

.sidebar.collapsed .toggle-button {
  position: fixed;
  left: 15px;
  top: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #333;
}

.recolher-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
