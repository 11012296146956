.licoes-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background: #f9f9f9;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  .licoes-sidebar h2 {
    margin-bottom: 20px;
  }
  
  .licoes-sidebar p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
  }
  
  .sidebar-buttons {
    margin-top: 20px; /* Ajuste a altura desejada */
    display: flex;
    gap: 10px;
    justify-content: flex-start; /* Alinha os botões no início da flex container */
  }
  
  .sidebar-buttons button {
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
  }