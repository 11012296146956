.layout {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.main-content {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
  margin-left: 250px;
  width: calc(100% - 250px);
  box-sizing: border-box;
  position: relative;
  padding: 20px;
}

.sidebar {
  width: 250px;
  min-width: 250px;
  background-color: #fff;
  border-right: 1px solid #e0e0e0;
  height: 100vh;
  overflow-y: auto;
  transition: all 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.sidebar.collapsed {
  min-width: 50px;
  width: 50px;
}

.toggle-sidebar {
  position: absolute;
  right: -12px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.toggle-sidebar:hover {
  background-color: #f0f0f0;
}

.sidebar.collapsed .toggle-sidebar {
  transform: rotate(180deg);
}

.sidebar.collapsed .sidebar-content {
  display: none;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.layout.sidebar-collapsed .main-content {
  margin-left: 50px;
  width: calc(100% - 50px);
}

.checklist-verificacao {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.entregaveis-table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}

/* Ajuste para telas menores */
@media (max-width: 768px) {
  .main-content {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .sidebar {
    width: 50px;
    min-width: 50px;
  }

  .sidebar-content {
    display: none;
  }
}